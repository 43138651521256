<section class="transaction-history-section">
  <div class="category-wrapper">
    <div class="d-flex">
      <ul class="option-list">
        <li class="item" *ngFor="let status of statuses">
          <button type="button" class="btn-option" [ngClass]="{'active': selectedStatus === status.id}" (click)="changeStatus(status.id)">{{status.label}}</button>
        </li>
      </ul>
    </div>
  </div>

  <div class="body-wrapper">
    <app-spinner [isLoading]="isLoading">
      <div class="history-list" *ngIf="histories.length > 0; else noData ">
        <app-card-transaction-history
          *ngFor="let history of histories"
          [transactionId]="history.number"
          [totalPrice]="history.grand_total"
          [status]="history.status_name"
          [date]="history.date"
          [productName]="history.product_name"
          [paymentUrl]="history.nexuspay_payment_url"
          [paymentMethod]="history.payment_method_name"
          [paymentType]="history.payment_method_type ?? ''"
          [promoCode]="history.promo_code"
          [totalBeforeDiscount]="history.price_before_discount"
          [vaBankLogo]="history.payment_method_logo"
          [vaName]="history.nexuspay_va_name"
          [vaNumber]="history.nexuspay_va_number"
        >
        </app-card-transaction-history>
      </div>

      <div class="show-more" *ngIf="!disabledShowMore()">
        <button type="button" class="btn btn-main btn-lg" (click)="loadMore()">Muat lebih banyak</button>
      </div>
    </app-spinner>

    <ng-template #noData>
      <div class="no-data-wrapper">
        <div class="card">
          <img class="failed-image" src="assets/images/icon/failed.png" alt="logo RajaVocer">
          <div class="card-text">Belum ada transaksi</div>
          <a [href]="['/']" class="btn btn-main">Top Up Voucher Sekarang</a>
        </div>
      </div>
    </ng-template>
  </div>
</section>
